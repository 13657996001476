<template>
	<div :class="themeClass">
		<div class="gold-mall-search-box flex-row-align-center ">
			<div v-for="(item,index) in list" :key='index' class="flex-row-center-center search-time pointer-color-theme">{{item}}</div>
			<el-date-picker class="margin-l-10" style="width: 287px;" v-model="value" type="date" placeholder="选择日期">
			</el-date-picker>
			<el-input class="margin-l-10" style="width: 380px;" placeholder="关键字搜索" prefix-icon="el-icon-search" v-model="searchText">
			</el-input>
			<div class="search-button margin-l-10 flex-row-center-center font-size13 font-color-333 pointer" @click="search">
				搜索
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	export default {
		data() {
			return {
				list: ["全部日期", "最近七天", "最近一个月", "最近一年"],
				value: '',
				searchText: ''
			};
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		mounted() {},
		methods: {
			search() {
				console.log(123);
			}
		}
	};
</script>

<style lang="scss" scoped>
	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed("themes_color");
		}
	}

	.gold-mall-search-box {
		height: 32px;
	}

	.search-time {
		width: 81px;
	}

	/deep/ .el-input__icon {
		line-height: 30px;
	}

	.search-button {
		width: 79px;
		height: 32px;
		background-color: #f8f7fa;
		border-radius: 4px;
		user-select: none;
	}

	.search-button:hover {
		@include themify($themes) {
			background-color: themed("themes_color");
		}

		color: #FFFFFF;
	}
</style>
