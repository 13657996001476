<template>
	<div :class="themeClass" class="width-fill">
		<div class="maink margin-lr-auto bg-fff margin-t-20">
			<breadcrumb></breadcrumb>
			<div class="margin-tb-8 font-size18 font-color-333 font-weight700">
				金币订单
			</div>
			<div class="top flex-row">
				<div class="tabs">
					<el-tabs v-model="activeName" @tab-click="handleClick">
						<el-tab-pane class="pointer-color-theme" label="全部日期" name="all"></el-tab-pane>
						<el-tab-pane class="pointer-color-theme" label="最近七天" name="sevendays"></el-tab-pane>
						<el-tab-pane class="pointer-color-theme" label="最近三个月" name="threemonth"></el-tab-pane>
						<el-tab-pane class="pointer-color-theme" label="最近一年" name="oneyear"></el-tab-pane>
					</el-tabs>
				</div>
				<div class="choose-date margin-l-10">
					<el-date-picker v-model="searchDate" unlink-panels type="daterange" range-separator="至" start-placeholder="开始日期"
					 end-placeholder="结束日期">
					</el-date-picker>
				</div>
				<div class="search-box margin-l-10">
					<el-input prefix-icon="el-icon-search" v-model="searchText"> </el-input>
				</div>
				<div class="pointer search-btn margin-l-10 text-center" @click="sleListData">搜索</div>
			</div>
			<!-- 我的订单主要内容 -->
			<!-- <div v-if="orders.length>0"> -->
			<el-tabs v-model="activeTabName" @tab-click="handleClicktab">
				<el-tab-pane class="pointer-color-theme" label="全部订单"></el-tab-pane>
				<el-tab-pane class="pointer-color-theme" label="已支付"></el-tab-pane>
				<el-tab-pane class="pointer-color-theme" label="已发货"></el-tab-pane>
				<el-tab-pane class="pointer-color-theme" label="已签收"></el-tab-pane>

			</el-tabs>

			<div class="flex-row-space-between-center font-size14 padding-lr-10 order-title-box margin-t-10 margin-b-5">
				<div class="flex-row-center-center order-title55">
					订单信息
				</div>
				<div class="flex-row-space-between-center order-title45">
					<div class="flex-row-center-center order-title50">数量</div>
					<div class="flex-row-center-center order-title50">金币</div>
					<div class="flex-row-center-center order-title50">状态</div>
					<div class="flex-row-center-center order-title50"></div>
				</div>
			</div>
			<div v-for="(item, index) in Data" :key="index" class="">
				<div class="flex-row-space-between-center padding-lr-10 list-title-box">
					<div class="flex-row-align-center font-size14 font-color-000">
						<div class="">{{ item.fCreateTime }}</div>
						<div class="flex-row-align-center margin-l-19">
							<div class="margin-r-5">订单编号:</div>
							<div class="color-theme">{{ item.fOrderNumber }}</div>
						</div>
					</div>
					<!-- <div class="flex-row-align-center font-size14 font-color-ff6666 pointer">
						<div class="iconfont icon-fenxiang font-size16"></div>
						分享订单
					</div> -->
				</div>
				<div class="flex-row-space-between-center shopCart padding-lr-10 bg-fff">
					<div class="flex-row-start-center order-title55" style="height: 65px;">
						<div class="flex-row-space-between-center">
							<div class="box-shadow-all imageRadius">
								<!-- <el-image class="imageRadius" :src="subItem.src"></el-image> -->
								<imageBox :width='65' :height='65' :borderRidus='10' :src="item.fPicturePath"></imageBox>
							</div>
						</div>
						<div class="margin-l-19 font-color-000">{{ item.fGoodsFullName }}</div>
					</div>
					<div class="flex-row-align-center order-title45 font-size14" style="height: 65px;">
						<div class="flex-row-center-center order-title50 border-left80  font-color-333">
							x
							<div class="margin-l-3">{{item.fPlanAmount}}</div>
						</div>
						<div class="flex-row-center-center order-title50 border-left80 font-color-000">
							{{ item.fGoldCoinPrice|NumFormat }}金币
						</div>
						<div class="flex-row-center-center order-title50 border-left80" :class="item.fPlyingStatus==0?'font-color-FF0000':'color-theme'">
							{{ item.fPlyingStatusName }}
						</div>
						<div class="flex-column-center-center order-title50 border-left80">
							<div class="flex-row-center-center pointer operation-button color-theme border-color-theme" @click="getDetail(item.fOrderID)"
							 :class="{'margin-b-10':item.fShipUrl != null}">详情</div>
							<div v-if="item.fShipUrl != null" class="flex-row-center-center pointer color-theme operation-button2 border-color-theme"
							 @click="toLogisticsInformation(item.fShipUrl)">查看物流</div>

						</div>
					</div>
				</div>
			</div>
			<div class="pagination">
				<pagination :currentPage="currentPage" :pagesize="pagesize" :total="total" @handleCurrentChange="handleCurrentChange"></pagination>
			</div>
		</div>
	</div>
</template>
<script>
	import imageBox from '@/components/imageBox/imageBox.vue'
	import {
		mapGetters
	} from 'vuex';
	import GoldMallSearch from '@/components/GoldMall/GoldMallSearch'
	import breadcrumb from '@/components/breadcrumb.vue';
	import pagination from "@/components/pagination/pagination.vue";
	export default {
		components: {
			breadcrumb,
			GoldMallSearch,
			imageBox,
			pagination
		},
		data() {
			return {
				searchText: '',
				searchDate: ['', ''],
				activeName: "all",
				activeTabName: '0',
				// 当前页数
				currentPage: 1,
				// 每页条数
				pagesize: 5,
				// 总条数
				total: 0,
				// 每个订单的商品数量及总价列表
				Data: [],
			};
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		mounted() {
			this.queryListData(this.activeTabName);
		},
		methods: {
			sleListData() {
				this.queryListData(this.activeTabName);
			},
			// 快捷切换时间
			handleClick(tab, event) {
				if (this.searchDate == null) {
					this.searchDate = ['', '']
				}
				const start = new Date();
				const end = new Date();
				switch (this.activeName) {
					case "all":
						this.searchDate[0] = ''
						this.searchDate[1] = ''
						break;
					case "sevendays":
						this.searchDate[0] = new Date(start.setTime(start.getTime() - 3600 * 1000 * 24 * 7))
						this.searchDate[1] = end
						break;
					case "threemonth":
						this.searchDate[0] = new Date(start.setTime(start.getTime() - 3600 * 1000 * 24 * 90))
						this.searchDate[1] = end
						break;
					case "oneyear":
						this.searchDate[0] = new Date(start.setTime(start.getTime() - 3600 * 1000 * 24 * 365))
						this.searchDate[1] = end
						break;
				}
				this.currentPage = 1;
				this.queryListData(this.activeTabName);
			},
			// 当前页 改变时会触发
			handleCurrentChange(Current) {
				this.currentPage = Current;
				this.queryListData(this.activeTabName);
			},
			//全部订单
			queryListData(type) {
				console.log(type);
				console.error(this.currentPage)
				this.ApiRequestPostNOMess("api/mall/ebsale/order-gold-coin/get-list", {
					beginTime: this.searchDate[0] == '' ? this.searchDate[0] : this.getDate(this.searchDate[0]),
					endTime: this.searchDate[1] == '' ? this.searchDate[1] : this.getDate(this.searchDate[1]),
					fSelStatus: type,
					filter: this.searchText,
					maxResultCount: this.pagesize,
					skipCount: (this.currentPage - 1) * this.pagesize
				}).then(res => {
					let data = res.obj.items
					for(var i = 0; i < data.length - 1; i++){
						for(var j = 0; j < data.length - i - 1; j++){
							if(new Date(data[j].fCreateTime).getTime() < new Date(data[j + 1].fCreateTime).getTime()){
								[data[j],data[j+1]] = [data[j+1],data[j]]
							}
						}
					}
					
					this.Data = [];
					this.Data = data
					this.Data.forEach(item => {
						item.fCreateTime = this.getTime(item.fCreateTime);
					});
					this.total = res.obj.totalCount;
				}).catch(err => {
					this.Data = [];
				})
			},
			//标签页点击事件
			handleClicktab(tab) {
				console.log(tab.index);
				this.currentPage = 1;
				this.activeTabName = tab.index;
				if (tab.index == '0') {
					//已支付
					this.queryListData(0);
				}
				if (tab.index == '1') {
					//已发货
					this.queryListData(1);
				}
				if (tab.index == '2') {
					//全部订单
					this.queryListData(2);
				}
				if (tab.index == '3') {
					//已签收
					this.queryListData(3);
				}
			},
			getDetail(id) {
				//详情页，传个数据过去

				this.$router.push({
					path: '/goldMall/GoldOrderDetail',
					query: {
						id: id
					}
				});
			},
			//查看物流信息
			toLogisticsInformation(url) {
				window.open(url, '_blank')
			}
		}
	};
</script>

<style lang="scss" scoped>
	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed("themes_color");
		}
	}

	.el-tabs--border-card {
		box-shadow: none;
		border: none;
	}


	/deep/.el-tabs__header {
		border: 1px solid #F2F2F2;
	}

	/deep/.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
		border-right-color: #F2F2F2;
		border-left-color: #F2F2F2;
		color: #fff;

	}

	/deep/.el-tabs--border-card>.el-tabs__header .el-tabs__item {
		color: #333333;
	}

	/deep/.el-tabs--border-card>.el-tabs__header .el-tabs__item:hover {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	/deep/ .el-tabs__content {
		// padding: 10px 0;
	}



	/* 订单为空的时候显示的内容CSS */
	.order .order-empty {
		width: 1225px;
		margin: 0 auto;
	}

	.order .order-empty .empty {
		height: 300px;
		padding: 0 0 130px 558px;
		margin: 65px 0 0;
		background: url(../../assets/imgs/cart-empty.png) no-repeat 124px 0;
		color: #b0b0b0;
		overflow: hidden;
	}

	.order .order-empty .empty h2 {
		margin: 70px 0 15px;
		font-size: 36px;
	}

	.order .order-empty .empty p {
		margin: 0 0 20px;
		font-size: 20px;
	}

	.order-title-box {
		height: 35px;
		background-color: #F2F4FD;
	}

	.order-title55 {
		width: 55%;
	}

	.order-title45 {
		width: 45%;
	}

	.order-title50 {
		width: 50%;
	}

	.list-title-box {
		height: 35px;
		background: #F2F4FD;
	}

	.shopCart {
		margin: 10px 0px;
		border: 1px solid #f2f2f2;
		height: 116px;
	}

	.imageRadius {
		width: 65px;
		height: 65px;
		border-radius: 10px;
	}

	.border-left80 {
		border-left: 1px solid #F2F2F2;
		height: 80px;
	}

	.operation-button {
		width: 73px;
		height: 22px;
		line-height: 22px;
		user-select: none;
	}

	.operation-button2 {
		width: 73px;
		height: 22px;
		line-height: 22px;
	}


	/deep/ [data-v-6ac52456] .el-input__icon {
		line-height: 22px;
	}

	/deep/ .el-tabs__item.is-active {
		color: #fff;

		@include themify($themes) {
			background-color: themed("themes_color");
		}
	}

	/deep/ [data-v-eb289ada] .el-input__icon {
		line-height: 22px;
	}

	/deep/ .el-date-editor .el-range-separator {
		line-height: 24px;
	}

	/deep/ .el-input--prefix .el-input__inner {
		padding-left: 40px;
	}

	.allOrder-Box {
		height: 100%;
		background-color: #ff3040;
	}

	.all-title {
		height: 24px;
		line-height: 24px;
		margin-bottom: 4px;
	}

	.top {
		width: 100%;
		height: 32px;
		margin-bottom: 15px;

		.el-date-editor.el-input,
		.el-date-editor.el-input__inner {
			width: 240px;
		}

		.search-box {
			width: 270px;
		}

		.search-btn {
			width: 79px;
			height: 32px;
			line-height: 32px;
			background: #f8f7fa;
			border-radius: 4px;
			border: 1px solid #f2f2f2;
		}
	}

	/deep/ .el-tabs__item {
		padding: 0;
		width: 75px;
		height: 32px;
		line-height: 32px;
		text-align: center;
	}

	/deep/ .el-tabs__active-bar {
		display: none;
	}

	/deep/ .el-tabs__nav-wrap::after {
		background-color: transparent;
	}

	/deep/ .el-input__inner {
		height: 32px;
		line-height: 32px;
	}

	/deep/ .el-input__icon {
		line-height: 32px;
	}

	.title {
		width: 100%;
		height: 35px;
		background: #f8f9fe;
	}

	.content {
		.nav {
			width: 100%;
			height: 35px;
			line-height: 35px;
			background: #f8f9fe;

			span {
				display: block;
				text-align: center;

				&:nth-child(1) {
					width: 425px;
				}

				&:nth-child(n + 2) {
					width: 123px;
				}
			}
		}
	}

	.pagination {
		margin-top: 20px;
		float: right;
	}

	/* 订单为空的时候显示的内容CSS END */
</style>
